import { currencyPrice } from '@payaca/helpers/financeHelper';
import { getAccountRegionFromCurrencyCode } from '@payaca/helpers/internationalHelper';
import { AccountRegions } from '@payaca/types/accountTypes';

/**
 * Formats a Provider GraphQL API's currency object into a human-readable string.
 */
export const humanizeCurrency = (currency: {
  value: number;
  currency?: {
    code: string;
  };
}) => {
  const region = currency.currency
    ? getAccountRegionFromCurrencyCode(currency.currency.code)
    : AccountRegions.UK;

  return currencyPrice(currency.value, region);
};
