import React, { useContext, useEffect, useRef, useState } from 'react';

import EditLineItemInformation from './EditLineItemInformation';
import EditLineItemPrice from './EditLineItemPrice';
import EditLineItemMaterials from './EditLineItemMaterials';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { isEqual } from 'lodash-es';

import { DynamicFeedbackContext } from '@payaca/components/context/DynamicFeedbackContext';

import * as lineItemV2Actions from '@payaca/store/lineItemsV2/lineItemsActions';
import * as lineItemActions from '@payaca/store/lineItems/lineItemsActions';

import { LineItem } from '@payaca/types/lineItemTypes';
import {
  DynamicFeedbackLifespanMs,
  FeedbackLevel,
} from '@payaca/types/feedbackTypes';
import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';

import { useSelector } from '@/api/state';
import { useFormState } from '../../../hooks/useFormState';

import { getLineItem } from '@/utils/stateAccessors';

export type FormState = {
  id?: LineItem['id'];
  name?: LineItem['name'];
  description?: LineItem['description'];
  attachments?: LineItem['attachments'];
  price?: LineItem['price'];
  cisDeductionRate?: LineItem['cisDeductionRate'];
  taxRateId?: LineItem['taxRateId'];
  markupPercentage?: LineItem['markupPercentage'];
  markupAmount?: LineItem['markupAmount'];
};
type FormValidationResult = Partial<
  Record<keyof FormState, FieldValidationResult>
>;
type Props = {
  lineItemId: number;
};
const EditItemControl = ({ lineItemId }: Props) => {
  const dispatch = useDispatch();

  const { showDynamicFeedbackMessage } = useContext(DynamicFeedbackContext);
  const clearDynamicFeedbackMessage = useRef<() => void>();

  const lineItem = useSelector((state) => getLineItem(state, lineItemId));

  const requestGetLineItem = () => {
    dispatch(lineItemV2Actions.requestGetLineItem(lineItemId));
  };
  const persistLineItem = (state: FormState) => {
    clearDynamicFeedbackMessage.current?.();
    return new Promise<void>((resolve, reject) => {
      dispatch(
        lineItemV2Actions.requestPersistLineItem(
          state,
          (lineItemId: number) => {
            requestGetLineItem();
            clearDynamicFeedbackMessage.current = showDynamicFeedbackMessage({
              title: 'Your changes have been saved',
              lifespanMs: DynamicFeedbackLifespanMs.SHORT,
              feedbackLevel: FeedbackLevel.SUCCESS,
            });
            resolve();
          },
          (error) => {
            clearDynamicFeedbackMessage.current = showDynamicFeedbackMessage({
              title: `We couldn't save your changes`,
              isCancellable: true,
              feedbackLevel: FeedbackLevel.ERROR,
            });
            reject(error);
          }
        )
      );
    }).then(() => {
      if (!isEqual(state.attachments, initialState.attachments)) {
        dispatch(
          lineItemActions.requestCreateUpdateLineItemAttachments(
            lineItemId,
            state.attachments || [],
            () => {
              requestGetLineItem();
            },
            () => {
              clearDynamicFeedbackMessage.current = showDynamicFeedbackMessage({
                title: `We couldn't save your changes`,
                isCancellable: true,
                feedbackLevel: FeedbackLevel.ERROR,
              });
            }
          )
        );
      }
    });
  };

  const initialState: FormState = {
    id: lineItem?.id,
    price: lineItem?.price,
    cisDeductionRate: lineItem?.cisDeductionRate,
    taxRateId: lineItem?.taxRateId,
    markupPercentage: lineItem?.markupPercentage,
    markupAmount: lineItem?.markupAmount,
    name: lineItem?.name,
    description: lineItem?.description,
    attachments: lineItem?.attachments,
  };
  const { formState, updateFormFields, formValidationResult } = useFormState<
    FormState,
    FormValidationResult
  >(
    initialState,
    {},
    {
      autoSaveFn: persistLineItem,
    }
  );
  return (
    <>
      {/* Item ref, description, image */}
      <EditLineItemInformation
        formState={formState}
        updateFormFields={updateFormFields}
        formValidationResult={formValidationResult}
      />

      {/* Item price, profit */}
      <EditLineItemPrice
        lineItemId={lineItemId}
        formState={formState}
        updateFormFields={updateFormFields}
      />

      {/* Item materials */}
      <EditLineItemMaterials
        lineItemId={lineItemId}
        onPersistLineItemMaterialSuccess={requestGetLineItem}
      />
    </>
  );
};

export default EditItemControl;
