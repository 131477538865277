import { useSelector } from '@/api/state';
import { useTranslation } from '@/i18n';
import MaterialHeader from '@/ui/components/materialHeader/MaterialHeader';
import MaterialLineItems from '@/ui/components/materialLineItems/MaterialLineItems';
import MaterialSupplierMaterials from '@/ui/components/materialSupplierMaterials/MaterialSupplierMaterials';
import { getMaterial } from '@/utils/stateAccessors';

import { requestGetMaterial } from '@payaca/store/materials/materialsActions';
import { requestGetUploadsForEntity } from '@payaca/store/uploads/uploadsActions';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';

import TabbedContentPageWrapper from '../pageWrappers/tabbedContentPageWrapper/TabbedContentPageWrapper';

import './MaterialPage.sass';

type Props = {
  materialId: number;
};

const MaterialPage: FC<Props> = ({ materialId }: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const [view, setView] = useState('Suppliers');

  useEffect(() => {
    dispatch(requestGetMaterial(materialId));
    dispatch(requestGetUploadsForEntity(materialId, 'material'));
  }, [materialId]);

  const tabs = useMemo(() => {
    const t = [
      {
        label: 'Suppliers',
        title: 'Suppliers',
        content: (
          <MaterialSupplierMaterials
            materialId={materialId}
            onMaterialUpdateSuccess={() =>
              dispatch(requestGetMaterial(materialId))
            }
          />
        ),
      },
      {
        label: 'Items',
        title: 'Items',
        content: <MaterialLineItems materialId={materialId} />,
      },
    ];

    return t;
  }, [materialId, dispatch]);

  const translate = useTranslation();

  const material = useSelector((state) => getMaterial(state, materialId));

  return (
    <>
      <Helmet
        title={translate('pages.material.title', {
          name: material?.name || '',
        })}
      />
      <TabbedContentPageWrapper
        className="material-page"
        title={
          <div className="title-bar-inner flex-grow">
            <MaterialHeader materialId={materialId} />
          </div>
        }
        breadcrumbItems={[
          {
            label: 'Materials',
            To: { pathname: '/materials' },
          },
          {
            label: material?.name || '...',
          },
        ]}
        tabbedContentConfig={{
          tabs: tabs,
          activeTabLabel: view,
          onChangeTab: (label: string) => setView(label),
        }}
      />
    </>
  );
};

export default MaterialPage;
