import { FC, useReducer, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { ManageableItemsList } from '@payaca/components/plManageableItemsList/ManageableItemsList';
import Button from '@payaca/components/plButton/Button';
import SearchMaterialsDrawer from '@/ui/components/searchMaterialsDrawer/SearchMaterialsDrawer';
import { EBtnSize } from '@payaca/components/plButton/useButtonClassName';
import useGetProjectMaterialsListSummaryItems, {
  MaterialPurchaseRecord,
} from '@/api/queries/project/useGetProjectMaterialListSummaryItems';
import PopulateDealMaterialsListControl from '@/ui/components/populateDealMaterialsListControl/PopulateDealMaterialsListControl';
import { useDispatch } from 'react-redux';
import { createMaterialsListMaterials } from '@payaca/store/materialsList/materialsListActions';
import * as materialsListActions from '@payaca/store/materialsList/materialsListActions';
import SkeletonLoader from '@payaca/components/plSkeletonLoader/SkeletonLoader';
import useGetProjectMaterialsListMaterials from '@/api/queries/project/useGetProjectMaterialsListMaterials';
import { RecordPurchaseModal } from './MaterialsPanel/RecordPurchaseModal';
import {
  DeleteMaterialListMaterial,
  DeleteMaterialsListMaterialModal,
} from './MaterialsPanel/DeleteMaterialsListMaterialModal';
import {
  EditMaterialsListMaterialModal,
  EditMaterialListMaterialQuantity,
} from './MaterialsPanel/EditMaterialsListMaterialModal';
import { DeleteMaterialPurchaseRecordModal } from './MaterialsPanel/DeleteMaterialPurchaseRecordModal';
import projectKeys from '@/api/queries/project/keyFactory';
import RouterTabs, {
  TabPanel,
} from '@payaca/components/plRouterTabs/RouterTabs';
import { SummaryPanel } from './MaterialsPanel/SummaryPanel';
import { ToOrderPanel } from './MaterialsPanel/ToOrderPanel';
import { OrderedPanel } from './MaterialsPanel/OrderedPanel';
import { PurchaseOrdersPanel } from './MaterialsPanel/PurchaseOrdersPanel';
import { CreatePurchaseOrderControl } from './MaterialsPanel/CreatePurchaseOrderModal';

interface Props {
  dealId: number;
}

const usePaginationState = (pageSize: number) => {
  const [currentPage, setCurrentPage] = useState(1);

  return {
    offset: (currentPage - 1) * pageSize,
    limit: pageSize,
    setCurrentPage,
  };
};

const PAGE_SIZE = 100; // Setting really high until we have search + filtering

export const MaterialsPanel: FC<Props> = ({ dealId }: Props) => {
  const [state, dispatch] = useReducer(modalReducer, { activeModal: null });

  const toOrderPaginationState = usePaginationState(PAGE_SIZE);
  const orderedPaginationState = usePaginationState(PAGE_SIZE);
  const summaryPaginationState = usePaginationState(PAGE_SIZE);

  const {
    materialsListOrderSummaryItems: toOrderItems,
    refetch: toOrderRefetch,
  } = useGetProjectMaterialsListSummaryItems(
    dealId,
    {
      statuses: ['TO_ORDER', 'ORDER_DRAFTED'],
    },
    {
      limit: toOrderPaginationState.limit,
      offset: toOrderPaginationState.offset,
    }
  );
  const {
    materialsListOrderSummaryItems: orderedItems,
    refetch: orderedRefetch,
  } = useGetProjectMaterialsListSummaryItems(
    dealId,
    {
      statuses: ['ORDERED', 'PURCHASED'],
    },
    {
      limit: orderedPaginationState.limit,
      offset: orderedPaginationState.offset,
    }
  );

  const {
    data,
    materialsListMaterials,
    refetch: mlmRefetch,
  } = useGetProjectMaterialsListMaterials(dealId, {
    limit: summaryPaginationState.limit,
    offset: summaryPaginationState.offset,
  });

  const materialsListId =
    (data?.project && +data.project.materialsList.id) || null;
  const onAddMaterials = useAddMaterials(dealId, materialsListId);
  const queryClient = useQueryClient();

  if (!materialsListMaterials || !materialsListId) {
    return <SkeletonLoader className="h-20 rounded-lg" />;
  }

  const selectedMaterialsListMaterial =
    state.activeModal === 'recordPurchase' &&
    toOrderItems?.items.find(
      (item) => item.materialsListMaterial.id === state.materialListMaterialId
    )?.materialsListMaterial;

  const tabPanels: TabPanel[] = [
    {
      label: 'Summary',
      slug: 'summary',
      render: () =>
        materialsListMaterials ? (
          <SummaryPanel
            projectId={dealId}
            summaryItems={materialsListMaterials}
            onPageChange={summaryPaginationState.setCurrentPage}
            onRequestDeleteMaterialsListMaterial={(mlm) => {
              dispatch({
                type: 'openDeleteMaterialsListMaterial',
                payload: { materialsListMaterial: mlm },
              });
            }}
            onRequestEditMaterialsListMaterial={(mlm) => {
              dispatch({
                type: 'openEditMaterialsListMaterial',
                payload: { materialsListMaterial: mlm },
              });
            }}
          />
        ) : null,
    },
    {
      label: 'To order',
      slug: 'to-order',
      render: () => (
        <ToOrderPanel
          summaryItems={toOrderItems}
          projectId={dealId}
          onPageChange={toOrderPaginationState.setCurrentPage}
          onRequestEditMaterialsListMaterial={(mlm) => {
            dispatch({
              type: 'openEditMaterialsListMaterial',
              payload: { materialsListMaterial: mlm },
            });
          }}
          onRequestDeleteMaterialsListMaterial={(mlm) => {
            dispatch({
              type: 'openDeleteMaterialsListMaterial',
              payload: { materialsListMaterial: mlm },
            });
          }}
          onRecordPurchase={(materialListMaterialId) =>
            dispatch({
              type: 'openRecordPurchase',
              payload: { materialListMaterialId },
            })
          }
        />
      ),
    },
    {
      label: 'Ordered',
      slug: 'ordered',
      render: () => (
        <OrderedPanel
          summaryItems={orderedItems}
          projectId={dealId}
          onPageChange={orderedPaginationState.setCurrentPage}
          onRequestDeleteMaterialPurchaseRecord={(row) => {
            if (row.__typename == 'MaterialsListOrderSummaryPurchasedItem') {
              dispatch({
                type: 'openDeleteMaterialPurchaseRecord',
                payload: {
                  materialPurchaseRecord: row.materialPurchaseRecord,
                },
              });
            }
          }}
        />
      ),
    },
    {
      label: 'Purchase Orders',
      slug: 'purchase-orders',
      render: () => (
        <PurchaseOrdersPanel
          projectId={dealId}
          materialsListId={materialsListId}
        />
      ),
    },
  ];

  return (
    <ManageableItemsList>
      <ManageableItemsList.HeaderBar
        heading="Materials"
        buttons={
          <>
            <PopulateDealMaterialsListControl
              dealId={dealId}
              onSyncComplete={() => {
                void queryClient.invalidateQueries(
                  projectKeys.materialsListMaterials(dealId)
                );
              }}
            />
            <CreatePurchaseOrderControl
              materialsListId={materialsListId}
              projectId={dealId}
            />
            <Button
              onClick={() =>
                dispatch({ type: 'openCreateMaterialsListMaterial' })
              }
              size={EBtnSize.Small}
            >
              Add Materials
            </Button>
          </>
        }
      />
      <RouterTabs panels={tabPanels}>
        <RouterTabs.Nav
          defaultTabSlug="summary"
          className="p-2 px-4"
          onChange={() => {
            summaryPaginationState.setCurrentPage(1);
            toOrderPaginationState.setCurrentPage(1);
            orderedPaginationState.setCurrentPage(1);
          }}
        />

        <RouterTabs.Panels />
      </RouterTabs>

      <SearchMaterialsDrawer
        isOpen={state.activeModal === 'createMaterialsListMaterial'}
        onClose={() => dispatch({ type: 'closeModal' })}
        onAddMaterials={onAddMaterials}
        disabledMaterialIds={
          toOrderItems?.items.map((item) =>
            Number.parseInt(item.materialsListMaterial.material.id)
          ) || []
        }
      />
      {selectedMaterialsListMaterial && (
        <RecordPurchaseModal
          materialsListId={materialsListId}
          isOpen
          materialAndSuppliers={selectedMaterialsListMaterial}
          onClose={() => dispatch({ type: 'closeModal' })}
          onSuccess={() => {
            void mlmRefetch();
            void toOrderRefetch();
            void orderedRefetch();
            dispatch({ type: 'closeModal' });
          }}
        />
      )}
      {state.activeModal === 'deleteMateralsListMaterial' && (
        <DeleteMaterialsListMaterialModal
          isOpen={true}
          onClose={() => dispatch({ type: 'closeModal' })}
          materialsListMaterial={state.materialsListMaterial}
          onDeleteSuccess={() => {
            void mlmRefetch();
            void toOrderRefetch();
            dispatch({ type: 'closeModal' });
          }}
        />
      )}
      {state.activeModal === 'editMateralsListMaterial' && (
        <EditMaterialsListMaterialModal
          isOpen={true}
          onClose={() => dispatch({ type: 'closeModal' })}
          materialsListMaterial={state.materialsListMaterial}
          onPersistSuccess={() => {
            void mlmRefetch();
            void toOrderRefetch();
            dispatch({ type: 'closeModal' });
          }}
        />
      )}
      {state.activeModal === 'deleteMaterialPurchaseRecord' && (
        <DeleteMaterialPurchaseRecordModal
          isOpen={true}
          onClose={() => dispatch({ type: 'closeModal' })}
          materialPurchaseRecord={state.materialPurchaseRecord}
          onDeleteSuccess={() => {
            void mlmRefetch();
            void toOrderRefetch();
            void orderedRefetch();
            dispatch({ type: 'closeModal' });
          }}
        />
      )}
    </ManageableItemsList>
  );
};

const useAddMaterials = (dealId: number, materialsListId: number | null) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return (materialIds: number[]) => {
    if (!materialsListId) {
      console.error('No materials list to add materials to');
      return;
    }
    dispatch(
      createMaterialsListMaterials.request({
        materialsListId,
        materialsToCreate: materialIds.map((materialId) => ({
          materialId,
          quantity: 1,
        })),
        callback: () => {
          dispatch(
            materialsListActions.requestGetMaterialsListWithRelatedEntities(
              materialsListId
            )
          );

          void queryClient.invalidateQueries(
            projectKeys.materialsListMaterials(dealId)
          );
          void queryClient.invalidateQueries(
            projectKeys.materialsListSummaryItems(dealId)
          );
        },
      })
    );
  };
};

type ModalState =
  | {
      activeModal: 'recordPurchase';
      materialListMaterialId: string;
    }
  | {
      // Ok so its not strictly a modal but it's a modal-like thing
      activeModal: 'createMaterialsListMaterial';
    }
  | {
      activeModal: 'deleteMateralsListMaterial';
      materialsListMaterial: DeleteMaterialListMaterial;
    }
  | {
      activeModal: 'deleteMaterialPurchaseRecord';
      materialPurchaseRecord: MaterialPurchaseRecord;
    }
  | {
      activeModal: 'editMateralsListMaterial';
      materialsListMaterial: EditMaterialListMaterialQuantity;
    }
  | {
      activeModal: null;
    };

type ModalAction =
  | {
      type: 'openRecordPurchase';
      payload: {
        materialListMaterialId: string;
      };
    }
  | {
      type: 'openCreateMaterialsListMaterial';
    }
  | {
      type: 'openDeleteMaterialsListMaterial';
      payload: {
        materialsListMaterial: DeleteMaterialListMaterial;
      };
    }
  | {
      type: 'openDeleteMaterialPurchaseRecord';
      payload: {
        materialPurchaseRecord: MaterialPurchaseRecord;
      };
    }
  | {
      type: 'openEditMaterialsListMaterial';
      payload: {
        materialsListMaterial: EditMaterialListMaterialQuantity;
      };
    }
  | {
      type: 'closeModal';
    };

const modalReducer = (state: ModalState, action: ModalAction): ModalState => {
  switch (action.type) {
    case 'openCreateMaterialsListMaterial':
      return {
        ...state,
        activeModal: 'createMaterialsListMaterial',
      };
    case 'openRecordPurchase':
      return {
        ...state,
        activeModal: 'recordPurchase',
        materialListMaterialId: action.payload.materialListMaterialId,
      };
    case 'openDeleteMaterialsListMaterial':
      return {
        ...state,
        activeModal: 'deleteMateralsListMaterial',
        materialsListMaterial: action.payload.materialsListMaterial,
      };
    case 'openDeleteMaterialPurchaseRecord':
      return {
        ...state,
        activeModal: 'deleteMaterialPurchaseRecord',
        materialPurchaseRecord: action.payload.materialPurchaseRecord,
      };
    case 'openEditMaterialsListMaterial':
      return {
        ...state,
        activeModal: 'editMateralsListMaterial',
        materialsListMaterial: action.payload.materialsListMaterial,
      };
    case 'closeModal':
      return {
        activeModal: null,
      };
    default:
      return state;
  }
};
