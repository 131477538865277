import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import qs from 'qs';
import { useQueryClient } from '@tanstack/react-query';

import ConfirmPaymentModal from '@/ui/components/confirmPaymentModal/ConfirmPaymentModal';
import DealPayments from '@/ui/components/dealPayments/DealPayments';
import DealScheduledEvents from '@/ui/components/dealScheduledEvents/DealScheduledEvents';
import { getBacsPendingPayments } from '@payaca/helpers/jobPaymentHelper';

import { actions as usersActions } from '../../../api/users';
import * as customerActions from '@payaca/store/customer/customerActions';
import * as dealsActions from '@payaca/store/deals/dealsActions';
import * as documentActions from '@payaca/store/documents/documentActions';
import * as jobContentActions from '@payaca/store/jobContent/jobContentActions';
import * as jobPaymentActions from '@payaca/store/jobPayments/jobPaymentsActions';
import * as jobActions from '@payaca/store/jobs/jobsActions';
import * as scheduledEventActions from '@payaca/store/scheduledEvents/scheduledEventsActions';
import * as tasksActions from '@payaca/store/tasks/tasksActions';
import * as notesActions from '@payaca/store/notes/notesActions';
import * as pipelineActions from '@payaca/store/pipelines/pipelineActions';
import { useDeal } from '@payaca/store/hooks/appState';
import { Helmet } from 'react-helmet';

import {
  getJobPaymentsByDealId,
  getJobsByDealId,
  getUserRoles,
} from '@/utils/stateAccessors';

import { DealProposalsInvoices as DealProposalsInvoicesV1 } from '@/ui/components/dealProposalsInvoices/DealProposalsInvoices.v1';

import { AccountsPermissions } from '@payaca/permissions/accounts/accounts.permissions';
import { MaterialsListPermissions } from '@payaca/permissions/materialsList/materialsList.permissions';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';

import './DealPage.sass';
import { DealProposals } from '@/ui/components/dealProposals/DealProposals';
import { DealInvoicesPayments } from '@/ui/components/dealInvoicesPayments/DealInvoicesPayments';
import { useSelector } from '@/api/state';
import { useTranslation } from '@/i18n';
import DealFiles from '../../components/dealFiles/DealFiles';
import EntityTimelogsControl from '@/ui/components/entityTimelogsControl/EntityTimelogsControl';
import { DealTasks } from '@/ui/components/dealTasks/DealTasks';
import AuthenticatedPageWrapper from '../pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';
import { PermissionGuard } from '@/ui/components/permissionGuard/PermissionGuard';
import RouterTabs, {
  TabPanel,
} from '@payaca/components/plRouterTabs/RouterTabs';
import useGetProjectOverview from '@/api/queries/project/useGetProjectOverview';
import AccordionWithChildCount from '@payaca/components/plAccordionWithChildCount/AccordionWithChildCount';
import ContactCard from '@/ui/pages/dealPage/components/ContactCard';
import Card, { CardSizeVariant } from '@payaca/components/plCard/Card';
import projectKeys from '@/api/queries/project/keyFactory';
import ProjectHeader from '@/ui/pages/dealPage/components/ProjectHeader';
import { getProjectOverviewAccordionPreferencesLocalStorageKey } from '@/helpers/localStorageKeyHelper';
import ProjectOverviewCards from '@/ui/pages/dealPage/components/ProjectOverviewCards';
import { IntegratedEmailsPermissions } from '@payaca/permissions/integrated-emails/integrated-emails.permissions';
import SendIntegratedEmailModal from '@/ui/components/sendIntegratedEmailModal/SendIntegratedEmailModal';
import TaskAccordion from '@/ui/pages/dealPage/components/TaskAccordion';
import ActivityPanel from '@/ui/pages/dealPage/components/ActivityPanel';
import { Contact } from '@/gql/graphql';
import NavbarActions from '@/ui/pages/dealPage/components/NavbarActions';
import { ProjectCustomFieldGroup } from '@/ui/components/projectCustomFieldGroup/ProjectCustomFieldGroup';
import CompliancePanel from '@/ui/pages/dealPage/components/CompliancePanel';
import { DealsPermissions } from '@payaca/permissions/deals/deals.permissions';
import { MaterialsPanel } from './components/MaterialsPanel';

type Props = {
  dealId: number;
};

const DealPage: FC<Props> = ({ dealId }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const queryClient = useQueryClient();
  const query = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  const [isSendEmailModalOpen, setIsSendEmailModalOpen] = useState(false);
  const { data: projectData, isLoading: isProjectLoading } =
    useGetProjectOverview(dealId);

  const [confirmPaymentId, setConfirmPaymentId] = useState<number>();

  const deal = useDeal(dealId);

  const isFetchingDeal = useSelector(
    (state) => state.deals?.deals && state.deals.deals[dealId]?.isFetching
  );
  const errorFetchingDeal = useSelector((state) => {
    return !!(state.deals?.deals && state.deals.deals[dealId]?.fetchFailedAt);
  });
  const jobs = useSelector((state) => {
    return getJobsByDealId(state, dealId);
  });
  const jobPayments = useSelector((state) =>
    getJobPaymentsByDealId(state, dealId)
  );

  const latestJob = useMemo(() => jobs[0], [jobs]);

  const [jobDataInitialised, setJobDataInitialised] = useState(false);

  useEffect(() => {
    if (!!deal && query.new === 'true') {
      if (!deal?.siteAddresses?.length) {
        // new deal with no site addresses - open add site address modal
        history.push(`#site-addresses`);
      }
    }
  }, [query, deal]);

  useEffect(() => {
    dispatch(pipelineActions.requestGetAccountPipelines());
  }, []);

  useEffect(() => {
    if (latestJob && !jobDataInitialised) {
      setJobDataInitialised(true);
      dispatch(
        // TODO why only do this for the main one? surely need for all?
        jobContentActions.requestGetJobContentWithJobGroupsAndJobLineItems(
          latestJob.jobContentId
        )
      );
    }
  }, [latestJob, jobDataInitialised]);

  useEffect(() => {
    if (jobPayments) {
      const pendingPayments = getBacsPendingPayments(jobPayments);
      if (pendingPayments.length) setConfirmPaymentId(pendingPayments[0].id);
    }
  }, [!!jobPayments.length]);

  useEffect(() => {
    dispatch(dealsActions.requestGetDeal(dealId));
    dispatch(jobActions.requestGetJobsForDeal(dealId));

    dispatch(jobContentActions.requestGetJobContentsForDeal(dealId));

    dispatch(jobPaymentActions.requestGetJobPaymentsForDeal(dealId));
    dispatch(scheduledEventActions.requestGetScheduledEventsForDeal(dealId));
    dispatch(documentActions.requestGetDocumentsForDeal(dealId));
    dispatch(tasksActions.requestGetTasksForDeal(dealId));
    dispatch(notesActions.getNotesForDeal.request({ dealId }));

    // there is no way for deal JLIs to change without navigating away from the deal page, so only need to run this once and don't need to include this in the onDealUpdateSuccess function
    dispatch(jobContentActions.requestGetJobLineItemsForDeal(dealId));
  }, [dealId]);

  useEffect(() => {
    dispatch(customerActions.clearCurrentCustomer());
    if (userHasRequiredPermission(userRoles, [AccountsPermissions.GET_USERS])) {
      dispatch(usersActions.getAccountUsers());
    }
  }, []);

  useEffect(() => {
    if (deal?.customerId) {
      dispatch(
        customerActions.requestGetAndSetCurrentCustomer(deal.customerId)
      );
    }
  }, [deal]);

  useEffect(() => {
    // redirect to "deals" page in the event we shouldn't be accessing this deal
    if (errorFetchingDeal) history.push('/deals');
  }, [errorFetchingDeal]);

  const onDealUpdateSuccess = useCallback(
    (updatedJobIds?: number[]) => {
      dispatch(dealsActions.requestGetDeal(dealId));
      if (updatedJobIds) {
        updatedJobIds.map((updatedJobId) => {
          dispatch(jobActions.requestGetJob(updatedJobId));
        });
      } else if (latestJob?.id) {
        dispatch(jobActions.requestGetJob(latestJob.id));
      }

      dispatch(jobPaymentActions.requestGetJobPaymentsForDeal(dealId));
      dispatch(scheduledEventActions.requestGetScheduledEventsForDeal(dealId));
      dispatch(documentActions.requestGetDocumentsForDeal(dealId));
      dispatch(tasksActions.requestGetTasksForDeal(dealId));
      dispatch(notesActions.getNotesForDeal.request({ dealId }));
      void queryClient.invalidateQueries({
        queryKey: projectKeys.project(dealId),
      });
    },
    [dealId, latestJob, queryClient]
  );

  const userRoles = useSelector(getUserRoles);

  const hasProtoInvoice = jobs.some(
    (job) => job.isProtoInvoice && !job.archivedAt && !job.inactivatedAt
  );

  const translate = useTranslation();

  const accordionPreferences = useMemo(() => {
    try {
      const _accordionPreferences = JSON.parse(
        localStorage.getItem(
          getProjectOverviewAccordionPreferencesLocalStorageKey()
        ) ?? '{}'
      );

      const dealAccordionPreferences = _accordionPreferences[dealId];

      return {
        customerContacts: dealAccordionPreferences?.customerContacts ?? true,
        accessContacts: dealAccordionPreferences?.accessContacts ?? true,
        tasks: dealAccordionPreferences?.tasks ?? true,
      };
    } catch (error) {
      return {
        customerContacts: true,
        accessContacts: true,
        tasks: true,
      };
    }
  }, [dealId]);

  const handleAccordionToggle = useCallback(
    (key: string) => (open: boolean) => {
      const _accordionPreferences = JSON.parse(
        localStorage.getItem(
          getProjectOverviewAccordionPreferencesLocalStorageKey()
        ) ?? '{}'
      );

      localStorage.setItem(
        getProjectOverviewAccordionPreferencesLocalStorageKey(),
        JSON.stringify({
          ..._accordionPreferences,
          [dealId]: {
            ..._accordionPreferences[dealId],
            [key]: open,
          },
        })
      );
    },
    [projectData]
  );

  const routerTabPanels = useMemo(() => {
    const panels: TabPanel[] = [];

    panels.push({
      label: 'Activity',
      slug: 'activity',
      render: () => <ActivityPanel />,
    });

    if (projectData && projectData.project.version > 1 && !hasProtoInvoice) {
      panels.push({
        label: 'Proposals',
        slug: 'proposals',
        render: () => (
          <DealProposals
            dealId={dealId}
            onDealUpdateSuccess={onDealUpdateSuccess}
          />
        ),
      });
    }

    if (projectData && projectData.project.version === 1) {
      panels.push({
        label: 'Proposals / Invoices',
        slug: 'proposals-and-invoices',
        render: () => (
          <DealProposalsInvoicesV1
            dealId={dealId}
            onDealUpdateSuccess={onDealUpdateSuccess}
          />
        ),
      });
    }

    if (projectData && projectData.project.version > 1) {
      panels.push({
        label: 'Invoices',
        slug: 'invoices-and-payments',
        render: () => (
          <DealInvoicesPayments
            dealId={dealId}
            onDealUpdateSuccess={onDealUpdateSuccess}
          />
        ),
      });
    } else {
      panels.push({
        label: 'Payments',
        slug: 'payments',
        render: () => (
          <DealPayments
            dealId={dealId}
            onDealUpdateSuccess={onDealUpdateSuccess}
          />
        ),
      });
    }

    panels.push({
      label: 'Schedule',
      slug: 'schedule',
      render: () => (
        <DealScheduledEvents
          dealId={dealId}
          onDealUpdateSuccess={onDealUpdateSuccess}
        />
      ),
    });

    panels.push({
      label: 'Tasks',
      slug: 'tasks',
      render: () => (
        <DealTasks dealId={dealId} onDealUpdateSuccess={onDealUpdateSuccess} />
      ),
    });

    panels.push({
      label: 'Files',
      slug: 'files',
      render: () => (
        <DealFiles dealId={dealId} onDealUpdateSuccess={onDealUpdateSuccess} />
      ),
    });

    if (
      userHasRequiredPermission(userRoles, [
        MaterialsListPermissions.GET_MATERIALS_LISTS,
      ])
    ) {
      panels.push({
        label: 'Materials',
        slug: 'materials',
        render: () => <MaterialsPanel dealId={dealId} />,
      });
    }

    if (
      userHasRequiredPermission(userRoles, [
        MaterialsListPermissions.GET_MATERIALS_LISTS,
      ])
    ) {
      panels.push({
        label: 'Timelogs',
        slug: 'timelogs',
        render: () => (
          <EntityTimelogsControl
            entity={{
              entityId: dealId.toString(),
              entityType: 'deal',
            }}
          />
        ),
      });
    }

    if (
      userHasRequiredPermission(userRoles, [
        DealsPermissions.GET_COMPLIANCE_FORMS,
      ])
    ) {
      panels.push({
        label: 'Compliance',
        slug: 'compliance',
        render: () => <CompliancePanel />,
      });
    }

    return panels;
  }, [projectData, hasProtoInvoice, userRoles, dealId, onDealUpdateSuccess]);

  const sendEmail = async (data: any) => {
    const token = localStorage.getItem('payacav1'); // TOKEN_KEY

    await fetch(
      `${import.meta.env.VITE_API_HOST}/api/deals/${dealId}/send_email`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }
    );
  };

  const siteAddressContacts = useMemo(() => {
    return (
      projectData?.project.addresses.site.reduce<Contact[]>((acc, site) => {
        acc.push(...site.contacts);

        return acc;
      }, []) || []
    );
  }, [projectData?.project.addresses.site]);

  return (
    <>
      <Helmet
        title={translate('pages.deal.title', {
          reference: projectData?.project.reference || '',
        })}
      />
      <AuthenticatedPageWrapper
        className="tabbed-content-page-wrapper deal-page"
        breadcrumbItems={[
          {
            label: 'Projects',
            To: '/deals',
          },
          {
            label: `Project: #${projectData?.project.reference}`,
          },
        ]}
        pageBanners={
          deal?.archivedAt
            ? [
                <div
                  key="0"
                  className="w-100 flex items-center justify-center bg-yellow-500 p-2"
                >
                  <span>This Project has been archived</span>
                </div>,
              ]
            : undefined
        }
      >
        <div className="md:grid-template-desktop flex flex-col bg-gray-50 md:grid md:items-start">
          <RouterTabs panels={routerTabPanels}>
            <div className="grid-area-item1 p-6 pb-0 md:pb-4 md:pe-4">
              <Card sizeVariant={CardSizeVariant.SM}>
                <Card.Body>
                  <ProjectHeader />

                  <ProjectOverviewCards
                    onDealUpdateSuccess={onDealUpdateSuccess}
                  />

                  <RouterTabs.Nav
                    className="z-sticky sticky top-[50px] -mb-4 hidden items-end md:top-[70px] md:-mb-5 md:grid"
                    defaultTabSlug="activity"
                    navSuffix={
                      !projectData?.project.archivedAt ? (
                        <NavbarActions
                          className="py-4 md:pb-5"
                          setIsSendEmailModalOpen={setIsSendEmailModalOpen}
                        />
                      ) : undefined
                    }
                  />
                </Card.Body>
              </Card>
            </div>

            <div className="grid-area-item2 p-6 md:sticky md:top-[70px] md:row-span-2 md:h-[calc(100vh-70px)] md:px-0 md:pe-4">
              <div className="h-full space-y-3.5 md:overflow-auto">
                <Card sizeVariant={CardSizeVariant.SM}>
                  <Card.Body>
                    <div className="space-y-3.5">
                      <AccordionWithChildCount
                        title="Customer Contacts"
                        defaultOpen={true}
                        onToggle={handleAccordionToggle('customerContacts')}
                        count={`${
                          projectData?.project.customer?.contacts.length || 0
                        }`}
                      >
                        {!projectData?.project.customer?.contacts.length && (
                          <p className="mt-2">
                            No customer contacts have been added
                          </p>
                        )}
                        {projectData?.project.customer?.contacts.map(
                          (contact) => {
                            return (
                              <ContactCard
                                key={contact.id}
                                variant="customerContact"
                                {...contact}
                              />
                            );
                          }
                        )}
                      </AccordionWithChildCount>
                      <AccordionWithChildCount
                        title="Access Contacts"
                        onToggle={handleAccordionToggle('accessContacts')}
                        count={`${siteAddressContacts.length}`}
                      >
                        {!siteAddressContacts.length && (
                          <p className="mt-2">
                            No access contacts have been added to this address
                          </p>
                        )}
                        {siteAddressContacts.map((contact) => {
                          return (
                            <ContactCard
                              key={contact.id}
                              variant="accessContact"
                              {...contact}
                            />
                          );
                        })}
                      </AccordionWithChildCount>
                    </div>
                  </Card.Body>
                </Card>
                <Card sizeVariant={CardSizeVariant.SM}>
                  <Card.Body>
                    <TaskAccordion onToggle={handleAccordionToggle('tasks')} />
                  </Card.Body>
                </Card>
                <PermissionGuard
                  renderIfHasPermissions={[
                    AccountsPermissions.MANAGE_CUSTOM_FIELD_VALUES,
                  ]}
                >
                  <Card sizeVariant={CardSizeVariant.SM}>
                    <Card.Body>
                      <ProjectCustomFieldGroup projectId={dealId} />
                    </Card.Body>
                  </Card>
                </PermissionGuard>
              </div>
            </div>

            <div className="grid-area-item3 p-6 pt-0 md:pe-4">
              <RouterTabs.Nav
                className="z-sticky sticky top-[50px] mb-4 md:hidden"
                defaultTabSlug="activity"
                navSuffix={
                  !projectData?.project.archivedAt ? (
                    <NavbarActions
                      setIsSendEmailModalOpen={setIsSendEmailModalOpen}
                    />
                  ) : undefined
                }
              />

              <RouterTabs.Panels />
            </div>
          </RouterTabs>
        </div>
      </AuthenticatedPageWrapper>

      {!!confirmPaymentId && (
        <ConfirmPaymentModal
          jobPaymentId={confirmPaymentId}
          isOpen={!!confirmPaymentId}
          onClose={() => {
            setConfirmPaymentId(undefined);
          }}
          confirmPaymentCallback={() => {
            setConfirmPaymentId(undefined);
            onDealUpdateSuccess();
          }}
        />
      )}

      <PermissionGuard
        renderIfHasPermissions={[IntegratedEmailsPermissions.SEND_EMAIL]}
      >
        <SendIntegratedEmailModal
          onClose={() => setIsSendEmailModalOpen(false)}
          isOpen={isSendEmailModalOpen}
          dealId={dealId}
        />
      </PermissionGuard>
    </>
  );
};

export default DealPage;
