import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import * as scheduledEventsActions from '@payaca/store/scheduledEvents/scheduledEventsActions';
import * as tasksActions from '@payaca/store/tasks/tasksActions';

import AuthenticatedPageWrapper from '../pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';
import ListedTasks, {
  ListedTasksTTask,
  ListedTasksPaginationInput,
} from '@/ui/components/listedTasks/ListedTasks';
import { PermissionGuard } from '@/ui/components/permissionGuard/PermissionGuard';
import { TasksPermissions } from '@payaca/permissions/tasks/tasks.permissions';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';
import CreateEditTaskModal from '@/ui/components/createEditTaskModal/CreateEditTaskModal';
import { Task } from '@payaca/types/taskTypes';
import { getUserRoles } from '@/utils/stateAccessors';
import { useSelector } from '@/api/state';
import { Helmet } from 'react-helmet';
import { useTranslation } from '@/i18n';
import TaskReadCompleteDrawer from '@/ui/components/taskReadCompleteDrawer/TaskReadCompleteDrawer';
import Button from '@payaca/components/plButton/Button';
import { GetTasksInput } from '../../../gql/graphql';
import useGetTasks from '../../../api/queries/tasks/useGetTasks';
import {
  getTasksCompletionStatusesLocalStorageKey,
  getTasksEventRelationshipStatusesLocalStorageKey,
  getTasksTypesLocalStorageKey,
  getTasksUserAssignmentKeyLocalStorageKey,
} from '../../../helpers/localStorageKeyHelper';

const tasksUserAssignmentKeyLocalStorageKey =
  getTasksUserAssignmentKeyLocalStorageKey();
const tasksCompletionStatusesLocalStorageKey =
  getTasksCompletionStatusesLocalStorageKey();
const tasksEventRelationshipStatusesLocalStorageKey =
  getTasksEventRelationshipStatusesLocalStorageKey();
const tasksTypesLocalStorageKey = getTasksTypesLocalStorageKey();

const ListedTasksPage: FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const isAddingOrEditingTask = useRouteMatch<{
    taskId: string;
  }>({
    path: `${path}/:taskId`,
    exact: true,
  });

  const userRoles = useSelector(getUserRoles);

  const profile = useSelector((state: any) => state.users.myProfile);
  const [isTaskPersisted, setIsTaskPersisted] = useState(false);

  const initialUserAssignments = userHasRequiredPermission(userRoles, [
    TasksPermissions.GET_TASKS,
  ])
    ? localStorage
        .getItem(tasksUserAssignmentKeyLocalStorageKey)
        ?.split(',')
        .filter((x) => !!x) || []
    : [];
  const initialCompletionStatuses = localStorage
    .getItem(tasksCompletionStatusesLocalStorageKey)
    ?.split(',')
    .filter((x) => x != '') || ['to do', 'in progress'];
  const initialEventRelationshipStatuses =
    localStorage
      .getItem(tasksEventRelationshipStatusesLocalStorageKey)
      ?.split(',')
      .filter((x) => x != '') || [];
  const initialTypes = localStorage
    .getItem(tasksTypesLocalStorageKey)
    ?.split(',')
    .filter((x) => x != '');
  const [getTasksPagination, setGetTasksPagination] =
    useState<ListedTasksPaginationInput>({
      limit: 100,
      offset: 0,
    });
  const [getTasksInput, setGetTasksInput] = useState<GetTasksInput>({
    searchTerm: '',
    assignedTo: initialUserAssignments,
    completionStatuses: initialCompletionStatuses,
    eventRelationshipStatuses: initialEventRelationshipStatuses,
    sortBy: 'deadlineDate',
    sortDirection: 'asc',
    types: initialTypes,
  });

  const {
    data: tasksData,
    isLoading: isLoadingTasks,
    refetch: refetchTasks,
  } = useGetTasks(getTasksPagination, getTasksInput);

  useEffect(() => {
    dispatch(scheduledEventsActions.clearScheduledEvents());
    dispatch(tasksActions.clearTasks());
  }, []);

  useEffect(() => {
    refetchTasks();
  }, [getTasksInput, getTasksPagination]);

  const canOnlyViewOwnTasks = useMemo(() => {
    return (
      !userHasRequiredPermission(userRoles, [TasksPermissions.PERSIST_TASK]) &&
      userHasRequiredPermission(userRoles, [
        TasksPermissions.PERSIST_SELF_CREATED_TASK,
      ])
    );
  }, [userRoles]);

  const newTaskBase = useMemo(() => {
    return {
      assignedToUserId: canOnlyViewOwnTasks ? profile.id : undefined,
    } as Task;
  }, [canOnlyViewOwnTasks, profile.id]);

  const translate = useTranslation();

  const listedTasks = useMemo(() => {
    return tasksData?.tasks.items
      .map((task) => ({
        __typename: task.__typename,
        id: task.id,
        name: task.name,
        deadlineDate: task.deadline,
        createdByUserId: task?.createdBy?.id,
        events:
          task?.events.map((event) => ({
            id: event.id,
            name: event.name,
            ...(event.customer && {
              customer: {
                id: event.customer?.id,
                name: event.customer?.name,
              },
            }),
          })) || [],
        project: {
          id: task.project?.id,
          reference: task.project?.reference,
          ...(task.project?.customer && {
            customer: {
              id: task.project.customer?.id,
              name: task.project.customer?.name,
            },
          }),
        },
        assignee: task.assignee
          ? {
              id: task.assignee.id,
              firstName: task.assignee.firstName,
              lastName: task.assignee.lastName,
              email: task.assignee.email,
              colour: task.assignee.colour,
              avatarUrl: task.assignee.avatarUrl,
            }
          : undefined,
        completionStatus: task.completionStatus,
      }))
      .filter((t) => !!t) as ListedTasksTTask[];
  }, [tasksData?.tasks.items]);

  const onUpdateInputData = (inputData: GetTasksInput) => {
    if ('assignedTo' in inputData) {
      localStorage.setItem(
        tasksUserAssignmentKeyLocalStorageKey,
        inputData.assignedTo?.join(',') || ''
      );
    }
    if ('completionStatuses' in inputData) {
      localStorage.setItem(
        tasksCompletionStatusesLocalStorageKey,
        inputData.completionStatuses?.join(',') || ''
      );
    }
    if ('eventRelationshipStatuses' in inputData) {
      localStorage.setItem(
        tasksEventRelationshipStatusesLocalStorageKey,
        inputData.eventRelationshipStatuses?.join(',') || ''
      );
    }
    if ('types' in inputData) {
      localStorage.setItem(
        tasksTypesLocalStorageKey,
        inputData.types?.join(',') || ''
      );
    }
    setGetTasksInput((x) => ({
      ...x,
      ...inputData,
    }));
  };

  return (
    <>
      <Helmet title={translate('pages.tasks.title')} />
      <AuthenticatedPageWrapper
        className="bg-gray-50"
        title={
          <div className="flex flex-grow flex-row">
            <h1 className="flex-grow">Tasks</h1>
            <PermissionGuard
              renderIfHasPermissions={[
                TasksPermissions.PERSIST_TASK,
                TasksPermissions.PERSIST_SELF_CREATED_TASK,
              ]}
            >
              <Button onClick={() => history.push(`/tasks/new`)}>
                Create Task
              </Button>
            </PermissionGuard>
          </div>
        }
      >
        <div className="px-4 pb-6 pt-0">
          <ListedTasks
            getTasksInput={getTasksInput}
            setGetTasksInput={onUpdateInputData}
            getTasksPagination={getTasksPagination}
            setGetTasksPagination={setGetTasksPagination}
            listedTasks={listedTasks}
            isLoadingTasks={isLoadingTasks}
            onPersistTaskSuccess={refetchTasks}
            totalCount={tasksData?.tasks.totalCount || 0}
          />
        </div>
        <CreateEditTaskModal
          task={newTaskBase}
          isOpen={isAddingOrEditingTask?.params.taskId === 'new'}
          onClose={() => {
            history.push('/tasks');
          }}
          onPersistTaskSuccess={() => {
            history.push('/tasks');
            refetchTasks();
          }}
        />
        <TaskReadCompleteDrawer
          isOpen={
            isAddingOrEditingTask
              ? isAddingOrEditingTask?.params.taskId !== 'new'
              : false
          }
          onClose={() => {
            history.push('/tasks');
            if (isTaskPersisted) {
              refetchTasks();
              setIsTaskPersisted(false);
            }
          }}
          taskId={
            isAddingOrEditingTask?.params.taskId
              ? parseInt(isAddingOrEditingTask.params.taskId)
              : undefined
          }
          onUpdateTaskSuccess={() => setIsTaskPersisted(true)}
          onDeleteTaskSuccess={refetchTasks}
        />
      </AuthenticatedPageWrapper>
    </>
  );
};

export default ListedTasksPage;
