import { ComponentPropsWithoutRef, FC } from 'react';
import { twMerge } from 'tailwind-merge';
import Card, { CardSizeVariant } from '../plCard/Card';

export interface IProps extends ComponentPropsWithoutRef<'div'> {}

const SkeletonLoader: FC<IProps> = (props) => {
  const { className, ...rest } = props;

  return (
    <div
      className={twMerge('animate-pulse rounded-full bg-gray-200', className)}
      {...rest}
    />
  );
};

const SkeletonLoaderCircle: FC<IProps> = (props) => {
  const { className, ...rest } = props;

  return <SkeletonLoader className={twMerge('h-8 w-8', className)} {...rest} />;
};
const SkeletonLoaderSquare: FC<IProps> = (props) => {
  const { className, ...rest } = props;

  return (
    <SkeletonLoader
      className={twMerge('h-8 w-8 b rounded-none	', className)}
      {...rest}
    />
  );
};

const SkeletonLoaderTitle: FC<IProps> = (props) => {
  const { className, ...rest } = props;

  return (
    <SkeletonLoader className={twMerge('h-5 w-full', className)} {...rest} />
  );
};

const SkeletonLoaderText: FC<IProps> = (props) => {
  const { className, ...rest } = props;

  return (
    <SkeletonLoader className={twMerge('h-3 w-full', className)} {...rest} />
  );
};

const SkeletonLoaderInput: FC = () => {
  return (
    <div className="space-y-2">
      <SkeletonLoaderTitle className="w-1/4" />
      <SkeletonLoader className="h-10 w-full rounded-lg" />
    </div>
  );
};

const SkeletonLoaderTextarea: FC = () => {
  return (
    <div className="space-y-2">
      <SkeletonLoaderTitle className="w-1/4" />
      <SkeletonLoader className="h-32 w-full rounded-lg" />
    </div>
  );
};

const SkeletonLoaderProjectActivityTimeline: FC = () => {
  return (
    <Card sizeVariant={CardSizeVariant.SM}>
      <Card.Body className="flex items-center gap-4">
        <SkeletonLoaderCircle className="flex-shrink-0" />

        <SkeletonLoaderText />
      </Card.Body>
    </Card>
  );
};
const SkeletonLoaderNotification: FC = () => {
  return (
    <div className="flex flex-row p-5 gap-5">
      <SkeletonLoaderSquare />
      <div className="flex flex-col gap-2">
        <SkeletonLoaderText className="w-10" />
        <SkeletonLoaderText className="w-20" />
      </div>
    </div>
  );
};

export default Object.assign(SkeletonLoader, {
  Square: SkeletonLoaderSquare,
  Circle: SkeletonLoaderCircle,
  Title: SkeletonLoaderTitle,
  Text: SkeletonLoaderText,
  Input: SkeletonLoaderInput,
  Textarea: SkeletonLoaderTextarea,
  ProjectActivityTimeline: SkeletonLoaderProjectActivityTimeline,
  Notification: SkeletonLoaderNotification,
});
